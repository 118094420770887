import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'My Portfolio', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'My Portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Raj Topale',
  subtitle: "I'm a Senior Software Developer",
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'I am Vighnarajendra, but people call me "Raj". 10+ years of experience designing & building end-to-end software solutions in cloud and on premises.',
  paragraphTwo:
    'I enjoy working for teams with passion to take on new challenges and build solutions for real-world problems.',
  paragraphThree:
    'I am incredibly passionate about all things Engineering, Cloud computing, Artificial Intelligence and Big Data.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    title: 'Senior Software Developer at Tracfone Wireless',
    info: 'Building software solutions based on AWS cloud platform',
    info2: '',
  },
  {
    id: nanoid(),
    title: 'Software Developer at Wells Fargo',
    info: 'Building backend applications for digital mortgage platform',
    info2: '',
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'rajtopale@outlook.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/rajtopale',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: '#',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
